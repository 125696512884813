<template>
  <div class="best-merchants p-y-25">
    <div class="container">
      <div class="row">
        <div
          class="merchant m-b-18 d-flex justify-content-start align-items-center white-color-bg radius-20 gap-12 p-y-14 p-x-12"
        >
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="radius-20 p-y-6 p-x-14 m-r-10 d-block"
            type="rect"
            :width="60"
            :height="60"
            animation="wave"
          /> -->

          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="fs-18 bold-font dark-gray-color-text"
            type="rect"
            :width="70"
            :height="30"
            animation="wave"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'SliderLoader',
  // components: { VueSkeletonLoader },
  data () {
    return {
      fixedWidth: '100%',
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './best-merchants';
</style>
