<template>
  <div class="offer-slider">
    <div class="row no-gutters align-items-center full-height">
      <div
        class="col-12 col-lg-7 col-xl-7 full-height d-flex align-items-center justify-content-center"
      >
        <div class="">
          <div class="img-container img-fluid full-height">
            <img
              :src="offer.offer_image"
              alt=""
            >
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-5 col-xl-5">
        <div class="small-content">
          <h3
            class="fs-20 bold-font white-color-text"
            @click="goToOffer"
          >
            {{ offer.offer_name }}
          </h3>

          <div
            class="see-offer bold-font main-color-bg white-color-text"
            @click="goToOffer"
          >
            <span class="bold-font"> {{ $t('home.seeOffer') }}</span>
          </div>
        </div>

        <div class="content">
          <div
            class="price d-flex justify-content-start align-items-center m-b-14"
          >
            <span
              class="main-color-bg fs-14 white-color-text medium-font radius-20 p-y-6 p-x-14 m-r-10 d-block"
            >{{ offer.actual_value }} {{ $t('account.egp') }}</span>
            <span
              class="dark-gray-color-text medium-font fs-12"
            >{{ offer.offer_value }} {{ $t('account.egp') }}</span>
          </div>
          <div class="details">
            <div
              class="title d-flex justify-content-start align-items-start gap-20"
            >
              <h3
                class="fs-32 bold-font black-color-text"
                @click="goToOffer"
              >
                {{ offer.offer_name }}
              </h3>
              <p
                class="pink-color-bg white-color-text p-y-6 p-x-14 font-weight-600 radius-20 fs-14 d-flex justify-content-start align-items-center gap-6"
              >
                <span>{{ $t('offers.discount') }}</span>
                <span>{{ offer.offer_discount }}</span>
              </p>
            </div>
            <p class="description fs-16 dark-gray-color-text m-t-16">
              {{ offer.desc }}
            </p>
            <div
              class="restaurant-info d-flex justtify-content-start align-items-center my-4"
            >
              <div class="restaurant-info-image">
                <img :src="offer.partners.part_logo">
              </div>
              <h4 class="mx-3">
                {{ offer.partners.part_name }}
              </h4>
              <div
                class="rate d-flex justify-content-center align-items-center"
              >
                <StarIcon />
                <span class="secondary-font gold-color-text">
                  {{ Math.round(offer.rate * 10) / 10 }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarIcon from '@/components/icons/StarIcon'

export default {
  name: 'OfferSlider',
  components: {
    StarIcon
  },
  props: {
    offer: {
      type: Object,
      required: true
    }
  },

  methods: {
    goToOffer () {
      const path = {
        name: 'offer-details',
        params: {
          id: this.offer.offer_id,
          offerName: this.offer.offer_name.replace(/[ "" 25%]/g, '-')
        }
      }
      if (this.$route.path !== path) this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './offer-slider';
</style>
