<template>
  <div class="latest-offers pt-5">
    <div
      class="filters m-b-22 d-flex justify-content-between align-items-center"
    >
      <base-dropdown
        v-if="latestOffersOptions.length"
        input-id="latestOffers"
        :options="latestOffersOptions"
        :value="latestOffersValue"
        @update-seletced="updateSelected"
      />
    </div>

    <template v-if="cards.length && !offerLoading">
      <div class="row">
        <div
          v-for="(card, index) in cards"
          :key="index"
          class="col-12 col-md-6 col-lg-4"
        >
          <BaseOfferCard :singleCardInfo="card" />
        </div>
      </div>
    </template>

    <!-- show message if only there is no offers in card-->
    <template v-else-if="!cards.length && !offerLoading">
      <h1 class="text-center m-y-40">
        {{ $t('home.noOffersToShow') }}
      </h1>
    </template>

    <template v-else>
      <div class="row">
        <div
          v-for="index in 9"
          :key="index"
          class="col-12 col-md-6 col-lg-4"
        >
          <offerCardLoading />
        </div>
      </div>
    </template>

    <div class="m-t-20 d-flex justify-content-center align-items-center">
      <base-button
        class="text-center"
        :text="$t('home.openAllOffers')"
        is-medium
        isRoute
        :routeUrl="{ name: 'offers' }"
        fixed-width="160px"
      />
    </div>
  </div>
</template>

<script>
import homeApi from '../../../services/home'
import offerCardLoading from '../../partials/base-offer-card/loading.vue'
import BaseOfferCard from '@/components/partials/base-offer-card/Index.vue'

export default {
  name: 'LatestOffers',
  components: { offerCardLoading, BaseOfferCard },
  data () {
    return {
      cards: [],

      coords: {
        long: '',
        lat: ''
      },

      singleProduct: {},

      offerLoading: true,

      latestOffersOptions: [],

      latestOffersValue: 6,
      offerPayload: {}
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    }
  },
  watch: {
    latestOffersValue () {
      this.getSectionOffers()
    }
  },
  created () {
    this.getOffers()
    this.getSectionOffers()

    //get user location to use it in filters
    this.locatorButton()
  },

  methods: {
    updateSelected ({ id, value }) {
      if (value == 'nearest') {
        if (!!navigator.geolocation) {
          this.locatorButton()
        }
      }
      this.latestOffersValue = value
    },

    // get user location to use it in filters
    locatorButton () {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.coords.long = position.coords.longitude
          this.coords.lat = position.coords.latitude
        },
        (error) => {}
      )
    },

    nearestOffers () {
      const payload = {
        section_id: this.latestOffersValue,
        longitude: this.coords.long,
        latlatitude: this.coords.lat
      }
      homeApi.getSectionOffers(payload).then((res) => {
        this.offerLoading = false
        this.cards = res.data.offers
      })
    },

    // to route me to offer page
    // setOfferId (id, name) {
    //   const offerName = name.replace(/[ "" 25%]/g, '-')
    //   const path = {
    //     name: 'offer-details',
    //     params: { id, offerName }
    //   }
    //   if (this.$route.path !== path) this.$router.push(path)
    // },

    getOffers () {
      homeApi.getOffersCategory().then((res) => {
        this.latestOffersOptions = res.data.sections.map((item) => {
          return {
            name: item.section_name,
            id: item.section_id,
            value: item.section_id
          }
        })
      })
    },

    getSectionOffers () {
      this.offerPayload = {
        section_id: this.latestOffersValue,
        limit: 9,
        longitude: this.coords.long,
        latitude: this.coords.lat
      }
      if (this.isLoggedIn) {
        this.offerPayload = {
          ...this.offerPayload,
          token: this.getUserToken
        }
      } else {
        this.offerPayload = {
          ...this.offerPayload
        }
      }

      this.offerLoading = true
      homeApi.getSectionOffers(this.offerPayload).then((res) => {
        this.offerLoading = false
        const category = res.data.offers.length
        // if (category >= 6) {
        //   category = 6
        // } else if (this.latestOffersValue == 'nearest') {
        //   this.nearestOffers()
        // }
        this.cards = res.data.offers
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './latest-offers';
</style>
