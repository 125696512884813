<template>
  <div class="home-page">
    <div class="container">
      <offers-slider />
      <Search />
      <div class="p-y-30">
        <LatestOffers />
      </div>

      <DownloadApp />
    </div>
    <BestMerchants />
    <Numbers />
  </div>
</template>

<script>
import DownloadApp from './download-app/Index.vue'
import LatestOffers from './latest-offers/Index.vue'
import BestMerchants from './best-merchants/Index.vue'
import OffersSlider from './offers-slider/Index.vue'
import Search from './search-bar/Index.vue'
import Numbers from './Numbers/Index.vue'

export default {
  name: 'Home',
  components: {
    DownloadApp,
    BestMerchants,
    LatestOffers,
    OffersSlider,
    Search,
    Numbers
  },
  data () {
    return {
      optionsMulti: [],

      valueMulti: [],

      optionsSingle: [],

      valueSingle: ''
    }
  },

  methods: {
    updateSelectedMulti ({ id, value }) {
      const selectedValue = this.optionsMulti.find((el) => el.value === value)
      this.valueMulti = window._.xor(this.valueMulti, [selectedValue])
    },

    updateSelectedSingle ({ id, value }) {
      this.valueSingle = value
    }
  }
}
</script>

<style>
.search-left svg {
  left: 7% !important;
}
.search-right svg {
  right: 7% !important;
}
</style>
