<template>
  <div class="row search-bar justify-content-center">
    <div class="col-sm-12 col-12 col-md-12 col-lg-6">
      <div @click="clearListOffers()">
        <SearchIcon
          v-if="searchTerm.length == 0"
          class="bi bi-search"
          :class="
            isActiveSearchInput && isListOffers
              ? 'activeSearchIcon'
              : 'bi-search'
          "
        />
      </div>

      <input
        v-model="searchTerm"
        :placeholder="$t('home.saveForToday')"
        input-id="search"
        class="search"
        :class="isActiveSearchInput ? 'activeInput' : ''"
        type="search"
        autofocus
        @input="filterOffers"
        @click="scroolToHight()"
      >

      <transition name="fade">
        <div
          v-if="isListOffers"
          class="py-3 w-400"
        >
          <div
            v-for="offer in allOffers"
            :key="offer.id"
            class="result my-2"
            @click="goToOffer(offer.offer_id, offer.offer_name)"
          >
            <div v-html="highlight(offer.offer_name, searchTerm)">
              {{ offer.offer_name }}
            </div>
            <p
              class="pink-color-bg white-color-text p-y-6 p-x-14 font-weight-600 radius-20 fs-14 d-flex justify-content-start align-items-center gap-6"
            >
              <span>{{ $t('offers.discount') }}</span>
              <span>{{ offer.offer_discount }}</span>
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import baseTextInput from '../../partials/base-text-input/Index'
import SearchIcon from '../../icons/Search'
import { modalMixin } from '../../../mixins/modal'

export default {
  name: 'Search',
  components: { baseTextInput, SearchIcon },
  mixins: [modalMixin],
  data () {
    return {
      searchTerm: '',
      openModal: false,
      isActiveSearchInput: false
    }
  },
  computed: {
    allOffers () {
      return this.$store.state.search.allOffers
    },

    isListOffers: {
      get () {
        return this.$store.state.search.isListOffers
      },
      set (value) {
        this.$store.commit('search/IS_LIST_OFFERS', value)
      }
    }
  },

  created () {
    this.clearListOffers()
  },

  methods: {
    scroolToHight () {
      window.scrollTo({ top: 320, behavior: 'smooth' })
    },

    filterOffers () {
      const payload = {
        key: this.searchTerm,
        app_version: ''
      }
      this.$store.dispatch('search/filterOffers', payload).then(() => {
        this.isActiveSearchInput = true
      })
    },

    highlight (words, query) {
      const reg = new RegExp(query, 'gi')
      return words.replace(reg, function (str) {
        return `<span style="color: black;">${str}</span>`
      })
    },

    goToOffer (id, offer) {
      const offerName = offer.replace(/[ 25%]/g, '-')
      this.$router.push({ name: 'offer-details', params: { id, offerName } })
    },

    clearListOffers () {
      this.$store.commit('search/CLEAR_LIST')
      this.isActiveSearchInput = false
    }
  }
}
</script>

<style lang="scss">
@import './search';
</style>
