<template>
  <div class="best-merchants p-y-50">
    <div class="container">
      <h3 class="m-b-26 text-center fs-20 bold-font dark-gray-color-text">
        افضل التجار هذا الاسبوع
      </h3>
      <div class="row">
        <div
          v-for="merchant in merchants"
          :key="merchant.id"
          class="col-12 col-md-6 col-lg-4 col-xl-3"
        >
          <div
            v-if="!loading"
            class="merchant m-b-18 d-flex justify-content-start align-items-center white-color-bg radius-20 gap-12 p-y-14 p-x-12"
          >
            <img
              class="radius-full"
              width="60"
              height="60"
              :src="merchant.part_logo"
              :alt="merchant.part_name"
            >
            <span class="fs-18 bold-font dark-gray-color-text">
              {{ merchant.part_name }}
            </span>
          </div>

          <!---Loading-->
          <div v-if="loading">
            <merchantsLoader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeService from '../../../services/home'
import merchantsLoader from './merchants-loader.vue'

export default {
  name: 'BestMerchants',
  components: { merchantsLoader },
  data () {
    return {
      merchants: [],
      loading: true
    }
  },
  created () {
    this.bestMerchant()
  },
  methods: {
    bestMerchant () {
      homeService.bestMerchant().then((res) => {
        this.loading = false
        this.merchants = res.data.data[0]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './best-merchants';
</style>
