<template>
  <div class="offers-slider m-t-30">
    <template v-if="loading">
      <SliderLoader />
    </template>

    <Carousel
      v-else
      :wrap-around="true"
      :settings="settings"
    >
      <Slide
        v-for="(offer, id) in offers"
        :key="id"
      >
        <OfferSlider
          class="pr-4"
          :offer="offer"
        />
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import OfferSlider from './offer-slider/Index'
import sliderApi from '../../../services/home'
import SliderLoader from './offer-slider/SliderLoader'

export default {
  name: 'OffersSlider',
  components: {
    OfferSlider,
    SliderLoader,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data () {
    return {
      loading: false,
      offers: [],
      settings: {
        dots: true,
        itemsToShow: 1,
        itemsToScroll: 1,
        autoplay: 5000,
        rows: 1,
        infinite: true,
        rtl: true
      }
    }
  },
  created () {
    this.getSliderInfo()
  },
  methods: {
    getSliderInfo () {
      this.loading = true

      sliderApi.getSlider().then((res) => {
        this.loading = false
        this.offers = res.data.offers
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './offers-slider';

.carousel__slide {
  padding: 10px;
}

:deep(.carousel__next) {
  right: 70px;
}

:deep(.carousel__prev) {
  left: 990px;
}

:deep(.carousel__prev),
:deep(.carousel__next) {
  top: 85%;
  box-sizing: content-box;
  border: 5px solid white;
  background-color: white !important;
}

:deep(.carousel__icon) {
  fill: $dark-gray-color;
}
</style>
