<template>
  <div class="numbers">
    <div class="container">
      <div class="row p-9">
        <div class="col-6">
          <div class="white-color-text text-center my-lg-0">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              type="rect"
              animation="wave"
              :height="80"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'StatisticsLoader',
  // components: { VueSkeletonLoader },
  data () {
    return {
      fixedWidth: '100%',
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './numbers';
</style>
