<template>
  <div class="download-app">
    <!-- <div class="container"> -->
    <div class="row justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="row align-items-center">
          <div class="col-12 col-lg-4 offset-lg-1">
            <div class="img-container">
              <img src="./download-app.png">
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <div class="content">
              <span class="fs-16 white-color-text">{{
                $t('home.findNearestOffers')
              }}</span>
              <p class="bold-font fs-26 white-color-text m-y-12">
                {{ $t('home.download') }}
              </p>
              <div class="apps-links gap-10">
                <a
                  href="https://apps.apple.com/us/app/waffarha/id1381223107"
                  target="_blank"
                >
                  <img src="./app-store.png">
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.waffarha.devewest"
                  target="_blank"
                >
                  <img src="./google-play.png">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'DownloadApp'
}
</script>

<style lang="scss" scoped>
@import './download-app';
</style>
