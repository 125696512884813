<template>
  <BaseLayout>
    <Home />
  </BaseLayout>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout'
import Home from '@/components/Home/Index'
export default {
  name: 'HomePage',
  components: { Home, BaseLayout },
  data: () => ({
    title: ''
  })
}
</script>

<style scoped></style>
