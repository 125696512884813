<template>
  <div class="numbers p-y-70">
    <div class="container">
      <div class="row p-9">
        <div
          v-for="(item, i) in statistics"
          :key="i"
          class="col-6 col-md-6 col-lg-3"
        >
          <div
            v-if="!loading"
            class="white-color-text text-center my-5 my-lg-0"
          >
            <p>
              {{ item.key }}
            </p>
            <h1 class="my-2">
              {{ item.value }}
            </h1>
            <p>
              {{ item.suffixes }}
            </p>
          </div>
          <StatisticsLoader v-if="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from '../../../services/home'
import StatisticsLoader from './loader.vue'

export default {
  name: 'Numbers',
  components: { StatisticsLoader },
  data () {
    return {
      statistics: [],
      loading: true
    }
  },
  created () {
    this.getStatistics()
  },
  methods: {
    getStatistics () {
      homeApi.getStatistics().then((res) => {
        this.loading = false
        this.statistics = res.data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './numbers';
</style>
