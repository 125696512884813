<template>
  <div class="offer-slider">
    <div
      class="row no-gutters flex-direction-row-reverse align-items-center full-height"
    >
      <div class="col-12 col-lg-7 col-xl-7 full-height">
        <div class="img-container full-height">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="img-container corner-radius full-height"
            type="rect"
            :width="fixedWidth"
            :height="500"
            animation="wave"
          /> -->
        </div>
      </div>

      <div class="col-12 col-lg-5 col-xl-5">
        <div class="content">
          <div
            class="price d-flex justify-content-start align-items-center m-b-14"
          >
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="radius-20 p-y-6 p-x-14 m-r-10 d-block"
              type="rect"
              :width="70"
              :height="30"
              animation="wave"
            /> -->

            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="radius-20 dark-gray-color-text medium-font fs-12"
              type="rect"
              :width="70"
              :height="30"
              animation="wave"
            /> -->
          </div>
          <div class="details">
            <div
              class="title d-flex justify-content-start align-items-start gap-20"
            >
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="radius-20 dark-gray-color-text medium-font fs-12"
                type="rect"
                :width="250"
                :height="30"
                animation="wave"
              /> -->
            </div>
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="radius-20 description fs-16 dark-gray-color-text m-t-16"
              type="rect"
              :width="300"
              :height="80"
              animation="wave"
            /> -->
            <div
              class="restaurant-info d-flex justtify-content-start align-items-center my-4"
            >
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="restaurant-info-image"
                type="circle"
                :width="50"
                :height="50"
                animation="wave"
              /> -->

              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="radius-20 mx-3"
                type="rect"
                :width="150"
                :height="30"
                animation="wave"
              /> -->
              <div
                class="rate d-flex justify-content-center align-items-center"
              >
                <!-- <StarIcon /> -->
                <span class="secondary-font gold-color-text">
                  <!-- {{ Math.round(offer.rate * 10) / 10 }} -->
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'SliderLoader',
  // components: { VueSkeletonLoader },
  props: {
    isCart: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      fixedWidth: '100%',
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './offer-slider';
.corner-radius {
  border-radius: 40px !important;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}
</style>
